const CACHE = "REPLACE_MBA_PWA_VERSION";
const offlineFallbackPage = "./offline.html";

// Install stage sets up the index page (home page) in the cache and opens a new cache
self.addEventListener("install", function(event) {
    console.log("Install Event processing");

    event.waitUntil(
        caches.open(CACHE).then(function(cache) {
            console.log("Cached offline page during install");
            return cache.add(offlineFallbackPage);
        }));
});


// If any fetch fails, it will look for the request in the cache and serve it from there first
self.addEventListener("fetch", function (event) {
    if (event.request.method !== "GET") return;

    event.respondWith(
        fetch(event.request)
            .then(function (response) {
                event.waitUntil(updateCache(event.request, response.clone()));
                return response;
            })
            .catch(function (error) {
                console.log("Network request Failed. Serving content from cache: " + error);
                return fromCache(event.request);
            })
    );
});

function fromCache(request) {
    // Check to see if you have it in the cache
    // Return response
    // If not in the cache, then return error page
    return caches.open(CACHE).then(function (cache) {
        return cache.match(request).then(function (matching) {
            if (!matching || matching.status === 404) {
                return Promise.reject("no-match");
            }
            return matching;
        });
    });
}

function updateCache(request, response) {
    return caches.open(CACHE).then(function (cache) {
        return cache.put(request, response);
    });
}
